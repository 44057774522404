var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bt-cif pa-5"},[_c('Header',{staticClass:"text-h6 font-weight-bold mb-5",attrs:{"title":"Pencairan Tabungan","titleIcon":"mdi-note-plus-outline"}}),_c('v-container',{staticClass:"pa-0"},[_c('div',{},[_c('div',{staticClass:"w-full elevation-3 rounded-lg pa-5 mb-5",style:({
          backgroundColor: _vm.$vuetify.theme.dark
            ? 'var(--dark-bg)'
            : 'var(--light-bg)',
        })},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.hari_transaksi,"label":"Hari Transaksi","required":"","rules":[(v) => !!v || 'Hari Transaksi is required']},model:{value:(_vm.form.hari_transaksi),callback:function ($$v) {_vm.$set(_vm.form, "hari_transaksi", $$v)},expression:"form.hari_transaksi"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.cm,"label":"Rembug","required":"","rules":[(v) => !!v || 'Rembug is required']},model:{value:(_vm.form.cm_code),callback:function ($$v) {_vm.$set(_vm.form, "cm_code", $$v)},expression:"form.cm_code"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.cif,"label":"ID Anggota","required":"","rules":[(v) => !!v || 'ID Anggota is required']},model:{value:(_vm.form.cif_no),callback:function ($$v) {_vm.$set(_vm.form, "cif_no", $$v)},expression:"form.cif_no"}}),_c('v-autocomplete',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","items":_vm.list.produk,"label":"Produk Tabungan","required":"","rules":[(v) => !!v || 'Produk Tabungan is required']},model:{value:(_vm.form.account_saving_no),callback:function ($$v) {_vm.$set(_vm.form, "account_saving_no", $$v)},expression:"form.account_saving_no"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Saldo","disabled":""},model:{value:(_vm.form.saldo),callback:function ($$v) {_vm.$set(_vm.form, "saldo", $$v)},expression:"form.saldo"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","type":"date","label":"Tanggal Transaksi"},model:{value:(_vm.form.tgl_transaksi),callback:function ($$v) {_vm.$set(_vm.form, "tgl_transaksi", $$v)},expression:"form.tgl_transaksi"}}),_c('v-text-field',{attrs:{"color":_vm.$vuetify.theme.dark
                    ? 'background-color: white'
                    : 'background-color: black',"autocomplete":"off","outlined":"","label":"Jumlah Penarikan","disabled":""},model:{value:(_vm.form.jumlah_penarikan),callback:function ($$v) {_vm.$set(_vm.form, "jumlah_penarikan", $$v)},expression:"form.jumlah_penarikan"}})],1)],1)],1)],1)]),_c('v-row',[_c('v-col',{staticClass:"pb-5 mb-5",attrs:{"cols":"6"}},[_c('router-link',{attrs:{"to":"/dtl/dashboard"}},[_c('v-btn',{staticClass:"purple lighten-1 white--text",attrs:{"block":""}},[_vm._v("Kembali ")])],1)],1),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"purple lighten-1 white--text",attrs:{"block":"","disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.doSave()}}},[_vm._v(" Simpan ")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":5000},model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.msg)+" ")]),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }