<template>
  <div class="bt-cif pa-5">
    <Header
      title="Pencairan Tabungan"
      titleIcon="mdi-note-plus-outline"
      class="text-h6 font-weight-bold mb-5"
    />
    <v-container class="pa-0">
      <div class="">
        <div
          class="w-full elevation-3 rounded-lg pa-5 mb-5"
          :style="{
            backgroundColor: $vuetify.theme.dark
              ? 'var(--dark-bg)'
              : 'var(--light-bg)',
          }"
        >
          <v-form ref="form" v-model="isFormValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  :items="list.hari_transaksi"
                  v-model="form.hari_transaksi"
                  label="Hari Transaksi"
                  required
                  :rules="[(v) => !!v || 'Hari Transaksi is required']"
                />
                <v-autocomplete
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  :items="list.cm"
                  v-model="form.cm_code"
                  label="Rembug"
                  required
                  :rules="[(v) => !!v || 'Rembug is required']"
                />
                <v-autocomplete
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  :items="list.cif"
                  v-model="form.cif_no"
                  label="ID Anggota"
                  required
                  :rules="[(v) => !!v || 'ID Anggota is required']"
                />
                <v-autocomplete
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  :items="list.produk"
                  v-model="form.account_saving_no"
                  label="Produk Tabungan"
                  required
                  :rules="[(v) => !!v || 'Produk Tabungan is required']"
                />
                <v-text-field
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  v-model="form.saldo"
                  label="Saldo"
                  disabled
                />
                <v-text-field
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  v-model="form.tgl_transaksi"
                  type="date"
                  label="Tanggal Transaksi"
                />
                <v-text-field
                  :color="
                    $vuetify.theme.dark
                      ? 'background-color: white'
                      : 'background-color: black'
                  "
                  autocomplete="off"
                  outlined
                  v-model="form.jumlah_penarikan"
                  label="Jumlah Penarikan"
                  disabled
                />
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
      <v-row>
        <v-col cols="6" class="pb-5 mb-5">
          <router-link to="/dtl/dashboard">
            <v-btn block class="purple lighten-1 white--text">Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn
            block
            class="purple lighten-1 white--text"
            @click="doSave()"
            :disabled="!isFormValid"
          >
            Simpan
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "@/components/Header";
import Camera from "@/components/Camera.vue";
export default {
  name: "Tabungan",
  components: {
    Toast,
    Header,
    Camera,
  },
  data() {
    return {
      list: {
        hari_transaksi: [
          {
            value: "1",
            text: "Senin",
          },
          {
            value: "2",
            text: "Selasa",
          },
          {
            value: "3",
            text: "Rabu",
          },
          {
            value: "4",
            text: "Kamis",
          },
        ],
        cm: [],
        cif: [],
        produk: [],
      },
      form: {
        hari_transaksi: null,
        cm_code: null,
        cif_no: null,
        account_saving_no: null,
        saldo: 0,
        tgl_transaksi: null,
        jumlah_penarikan: 0,
      },
      isFormValid: true,
      alert: {
        show: false,
        msg: "",
      },
      overlay: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    "form.hari_transaksi": {
      handler: function (newValue) {
        if (newValue) {
          this.getInfoRembug();
        }
      },
      immediate: true,
    },
    "form.cm_code": {
      handler: function (newValue) {
        if (newValue) {
          this.getInfoMember();
        }
      },
      immediate: true,
    },
    "form.cif_no": {
      handler: function (newValue) {
        if (newValue) {
          this.getTabunganDetail();
        }
      },
      immediate: true,
    },
    "form.account_saving_no": {
      handler: function (newValue) {
        if (newValue) {
          this.setInfoTabungan();
        }
      },
      immediate: true,
    },
    overlay: {
      handler: function (newValue) {
        if (newValue) {
          this.overlay = true;
        } else {
          this.overlay = false;
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...helper,
    async getInfoRembug() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("fa_code", this.user.fa_code);
      payload.append("hari_transaksi", this.form.hari_transaksi);
      try {
        const req = await services.infoRembug(payload, this.user.token);
        if (req.status === 200) {
          if (Array.isArray(req.data.data)) {
            const cm =
              req.data.data.map((item) => {
                return {
                  value: item.cm_code,
                  text: item.cm_name,
                };
              }) || [];
            this.list.cm = cm;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getInfoMember() {
      this.overlay = true;
      let payload = new FormData();
      payload.append("cm_code", this.form.cm_code);
      try {
        const req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          const cif =
            req.data.data.map((item) => {
              return {
                value: item.cif_no,
                text: item.nama,
              };
            }) || [];

          this.list.cif = cif;
          this.list.cifs = req.data.data || [];
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
      this.overlay = false;
    },
    async getTabunganDetail() {
      let payload = new FormData();
      payload.append("cif_no", this.form.cif_no);

      try {
        const req = await services.TabunganDetail(payload, this.user.token);
        if (req.status === 200) {
          req.data.data.map((item) => {
            this.list.produk.push({
              value: item.account_saving_no,
              text: item.product_name,
              data: item,
            });
          });

          this.list.products = req.data.data || [];
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
    async setInfoTabungan() {
      const row = this.list.products.find(
        (item) => item.account_saving_no === this.form.account_saving_no
      );
      this.form.saldo = this.formatPrice(row.saldo) || 0;
      this.form.jumlah_penarikan = this.formatPrice(row.saldo) || 0;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    async doSave() {
      let payload = new FormData();
      payload.append("account_saving_no", this.form.account_saving_no);
      payload.append("trx_date", this.form.tgl_transaksi);
      payload.append("amount", this.form.jumlah_penarikan);
      payload.append("created_by", this.user.fa_code);

      try {
        let req = await services.pencairanTabungan(payload, this.user.token);
        if (req.data.status === true) {
          this.alert = {
            show: true,
            msg: "Pencairan Tabungan Berhasil",
          };
          setTimeout(() => {
            this.$router.push(`/tabungan`);
          }, 1500);
        } else {
          this.alert = {
            show: true,
            msg: req.data.msg,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      }
    },
  },
};
</script>
